<template>
	<div class="myBox">
		<TOP title="个人中心" :isSearch="true" history="/my"></TOP>
		<div class="main">
			<div class="infoBox">
				<div class="infoDiv">
					<div class="headIcon"><img class="headIcon" v-if="userInfo.avatar" :src="userInfo.avatar" /></div>
					<div class="infoDetail">
						<div class="name">昵称：{{userInfo.nickname}}</div>
						<div class="ID">ID:{{userInfo.uid}}</div>
					</div>
					<img @click="goMyEdit" class="editBtn" src="../../assets/images/my_edit.png" />
				</div>
				<div class="infoNumber">
					<span>获赞 {{userInfo.liked_num}}</span>
					<span @click="goNotice">关注 {{userInfo.follow_num}}</span>
					<span>粉丝 {{userInfo.follower_num}}</span>
				</div>
			</div>
			<div class="functionBox">
				<div class="functionItem" @click="shouChangFun">
					<div class="functionIcon"><img src="../../assets/images/my_shuochang.png" /></div>
					<div class="titlename">收藏</div>
				</div>
				<div class="functionItem" @click="goMessage">
					<div class="functionIcon" >
						<img class="Icon" src="../../assets/images/my_pinglun.png" />
						<span class="pinglunNum" v-if="userInfo.msg_count>0">{{userInfo.msg_count}}</span>
					</div>
					<div class="titlename" >评论/回复</div>
				</div>
			</div>
			<!--收藏列表-->
			<TIELIST from="my_shouchang" v-if="showShouChang"></TIELIST>
		</div>
		<!--底部菜单-->
		<MENUE current='my'></MENUE>
	</div>
</template>

<script>
	import {
		defineComponent
	} from 'vue';
	import TOP from '@/components/top/top.vue';
	import MENUE from '@/components/menue/menue.vue';
	import TIELIST from '@/components/tie/list.vue';
	import { mapGetters } from 'vuex';
	import request from '@/service/request'
	export default defineComponent({
		name: 'Home',
		components: {
			TOP: TOP,
			MENUE: MENUE,
			TIELIST
		},
		mounted() {
			document.querySelector("body").setAttribute("style",
				"background: linear-gradient(0deg, #EDEDD0, #DBEFE7);");
		},
		unmounted() {
			document.querySelector("body").setAttribute("style",
				"background: linear-gradient(#95C5B5, #64A4A4) no-repeat;");
		},
		computed: {
		    ...mapGetters(['testToKen'])
		},
		data(){
			return{
				userInfo:{},
				showShouChang:false
			}
		},
		created(){
			this.getDetail();
		},
		methods:{
			getDetail() {
				request.apiAxios({
					method: 'post',
					url: request.INFO,
				}).then((res) => {
					if (res.status) {
						this.userInfo=res.data;
						window.localStorage.setItem('userInfo',JSON.stringify(res.data));
					}
				}).catch((err) => {
					console.log(err);
				})
			},
			goMessage(){
				this.$router.replace({path:'/message',query:{history:'/my'}});
			},
			goNotice(){
				this.$router.replace({path:'/notice',query:{history:'/my'}});
			},
			shouChangFun(){
				this.showShouChang=true;
			},
			
		},
		setup(){
			function goMyEdit(){
				this.$router.replace({path:'/myEdit',query:{history:'/my'}});
			}
			return{
				goMyEdit
			}
		}
	});
</script>

<style lang="less" scoped="scoped">
	.main {
		padding: 0.2rem;
	}

	.infoBox {
		padding: 0.08rem;
		background: #F8FAF5;
		box-shadow: 0px 1px 2px 0px rgba(161, 161, 136, 0.36);
		border-radius: 0.14rem;
		margin-bottom: 0.26rem;
	}

	.infoDiv {
		padding: 0.24rem;
		display: flex;
		align-items: center;
		border-bottom: 0.02rem solid #DBDED4;
		justify-content: space-between;
		margin-bottom: 0.16rem;

		.headIcon {
			width: 1.25rem;
			height: 1.25rem;
			flex: none;
			margin-right: 0.18rem;
			border-radius:0.14rem;
		}

		.infoDetail {
			flex: auto;
			margin-right: 0.4rem;
			color: #5D6262;

			.name {
				font-size: 0.33rem;
				margin-bottom: 0.1rem;
			}

			.ID {
				font-size: 0.25rem;
			}
		}

		.editBtn {
			width: 0.46rem;
			height: 0.46rem;
			flex: none;
		}
	}

	.infoNumber {
		color: #5D6262;
		margin-bottom: 0.08rem;

		span {
			display: inline-block;
			width: 33.33%;
			height: 0.58rem;
			text-align: center;
			border-right: 0.02rem solid #DBDED4;
			line-height: 0.58rem;

			&:last-child {
				border: none;
			}
		}
	}

	.functionBox {
		background: #F8FAF5;
		box-shadow: 0px 1px 2px 0px rgba(161, 161, 136, 0.36);
		border-radius: 0.14rem;
		color:#5D6262;
		display: flex;
		padding:0.23rem 0.22rem 0;
		flex-wrap:wrap;
		margin-bottom:0.26rem;
		.functionItem{			
			margin:0 0.3rem 0.3rem;
			flex:none;
			
		}
		.functionIcon{			
			height:1.05rem;
			position: relative;
			text-align: center;
			img{width: 1.05rem;}
		}
		.pinglunNum{
			height: 0.4rem;
			border-radius: 0.26rem;
			padding:0 0.08rem;
			background: #ED5859;
			border-radius: 50%;
			font-size:0.25rem;
			position: absolute;
			right: -0.1rem;
			top:-0.1rem;
			color:#fff;
			width: 0.4rem;
			display: flex;
			align-items: center;
			overflow: hidden;
			justify-content: center;
		}
		.titlename{
			text-align: center;
			font-size:0.25rem;
			flex:none;
		}
	}
</style>
